@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}
.w-12{
  width: 8rem!important;
  }
  
.status-active {
  background: #3cbe1b;
  clip-path: circle(50%);
  height: 0.75em;
  width: 0.75em;
  margin-right: 5px;
}

.status-inactive {
  background: #ffee00;
  clip-path: circle(50%);
  height: 0.75em;
  width: 0.75em;
  margin-right: 5px;
}
